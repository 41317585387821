import { useRef, useEffect } from 'react';
import useAuth from '../../Context/Auth';

interface WordReaderProps {
  separatedWord: string;
  onComplete: () => void;
  englishVersion?: string;
}

const WordReader = ({
  separatedWord,
  onComplete,
  englishVersion,
}: WordReaderProps) => {
  const msg = new window.SpeechSynthesisUtterance();
  const synthesis = window.speechSynthesis;
  const isWordSpoken = useRef(false);
  const audio = useRef<HTMLAudioElement>();

  const { user } = useAuth();

  useEffect(() => {
    // isWordSpoken is used to make sure this useEffect does not trigger its callback twice in strict mode.
    if (!isWordSpoken.current) {
      audio.current = new Audio();

      /**
       * Handle resetting player when it ends.
       */
      audio.current.addEventListener('ended', onComplete);

      const completeWord = separatedWord.replace(/-/g, '');

      try {
        fetch(
          `/all_words/${user.english_version}/${completeWord
            .toLowerCase()
            .replace("'", '')}.mp3`
        ).then((res) => {
          res
            .blob()
            .then((blob) => {
              if (audio.current) {
                audio.current.currentTime = 0;
                audio.current.src = URL.createObjectURL(blob);
                audio.current.play();
              }
            })
            .catch((err) => {
              throw new Error(err);
            });
        });

        return;
      } catch (e) {
        console.log('Falling back to default voice agent');
      }

      msg.text = completeWord;

      const voiceList = synthesis.getVoices();

      //TODO: Improve the implementation for voice agents
      let foundVoice = voiceList.find(
        (voice) => voice.name === 'Google UK English Female'
      );

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) => voice.name === 'Karen' && voice.lang === 'en-AU'
        );
      }

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) =>
            voice.name === 'Shelley (English (United Kingdom))' &&
            voice.lang === 'en-GB'
        );
      }

      if (!foundVoice) {
        foundVoice = voiceList.find(
          (voice) =>
            voice.name === 'English United States' && voice.lang === 'en_US'
        );
      }

      if (foundVoice) {
        msg.voice = foundVoice;
        foundVoice.name === 'Google UK English Female'
          ? (msg.rate = 1)
          : (msg.rate = 0.8);
      } else {
        onComplete();
        return;
      }

      synthesis.cancel(); //clear the queue
      synthesis.speak(msg);

      msg.onend = () => {
        onComplete();
      };
    }

    return () => {
      isWordSpoken.current = true;
      audio?.current?.pause();
      audio?.current?.removeEventListener('ended', onComplete);
    };
  }, []);

  return <></>;
};

export default WordReader;
