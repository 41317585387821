import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import ReactHowler from 'react-howler';

import AppSpeakingAnimation from '../../Assets/LottieAnimations/SpeechSession/appSpeaking.json';
import WordReader from '../WordReader';

import { ISound } from '../../Utils/constants/interfaces';

import styles from './styles.module.scss';
import { Stack } from '@mui/material';

interface AudioPlayerProps {
  audioList?: ISound[];
  wordToRead?: string;
  onPlayerComplete: () => void;
  setActiveIndex?: Dispatch<SetStateAction<number>>;
  englishVersion?: string;
}

const AudioPlayer = ({
  audioList,
  wordToRead,
  onPlayerComplete,
  setActiveIndex,
  englishVersion,
}: AudioPlayerProps) => {
  const playerRef = useRef<ReactHowler>(null);
  const [audioListIndex, setAudioListIndex] = useState<number>(0);
  const [isAudioPlayerActive, setIsAudioPlayerActive] = useState<boolean>(true);

  useEffect(() => {
    /**
     * Before the audio player begins, the app is in the recording state and we want no cards to be disabled. Hence, activeIndex has the value -1. As soon as the audio player is mounted, we want the first card to be highlighted and rest to be disabled, hence it will be incremented by one on mounting. The ternary operator condition is to make sure that everything runs fine in development with Strict mode i.e. if useEffect is called twice, the value is not updated twice.
     */
    setActiveIndex
      ? setActiveIndex((prevIndex) =>
          prevIndex === -1 ? prevIndex + 1 : prevIndex
        )
      : null;
    if (!audioList || audioList.length === 0) {
      setIsAudioPlayerActive(false);
    }
  }, []);

  const playNextAudio = () => {
    if (audioList && audioListIndex === audioList.length - 1) {
      /**
       * The above condition means that the last audio file has been played.
       * This means that either the recorder will continue or the the complete word will be read.
       * In either case, we can remove the disabled effect from the cards.
       */
      setActiveIndex ? setActiveIndex(-1) : null;

      if (wordToRead) {
        // If wordToRead is yet to be pronounced after the last audio file is played.
        // In this case, onPlayerComplete will be called by the WordReader, as it is passed as props to the onComplete prop.
        setIsAudioPlayerActive(false);
      } else {
        // If no word is to be pronounced after the last audio file. Send an indication to the parent component that the AudioPlayer has finished.
        onPlayerComplete();
      }
    } else {
      /**
       * audioListIndex is incremented if the previously played file is not the last for the current flashcard.
       * playAudioPromise() is called using a reference to the player for the following reason:
       * There can be a boundary-case where two consecutive portions of words will refer to the same audio. In this case, even if the audioListIndex updates, the src prop being passed to the ReactAudioPlayer will not update as no change will take place in the string being passed, and hence the audio will not played.
       * So, we manually play the audio everytime the audioListIndex is updated. The autoPlayAfterSrcChange has been set to false just as a precaution since we're playing the audio files ourselves.
       * Everytime the audioListIndex will change, it will trigger the below useEffect hook. This has been done with a useEffect hook to make sure that audioListIndex has updated when the playAudioPromise function is called.
       * autoPlay has also been set to false just to be on the safer side, as the below useEffect also runs when the component is mounted.
       */
      setAudioListIndex((prevIndex) => prevIndex + 1);
      setActiveIndex ? setActiveIndex((prevIndex) => prevIndex + 1) : null;
    }
  };

  return (
    <Stack className={styles['top-container']}>
      <Player
        className={styles['animation']}
        autoplay
        loop
        src={AppSpeakingAnimation}
        speed={1}
      />
      {audioList && audioList?.length > 0 && (
        <ReactHowler
          ref={playerRef}
          format={['wav']}
          src={`/SampleSounds/${audioList[audioListIndex].audio_file}`}
          playing={false}
          onEnd={() => {
            playNextAudio();
          }}
          /**
           * play function is called on load due to some weird bug which causes ReactHowler to play the last audio file twice.
           * Reference: https://github.com/goldfire/howler.js/issues/899
           */
          onLoad={() => {
            playerRef.current?.howler.play();
          }}
        />
      )}

      {/* isAudioPlayerActive will be the flag used to trigger the WordReader */}
      {/* Added wordToRead in the condition to remove linter error of wordToRead possibly being undefined */}
      {!isAudioPlayerActive && wordToRead && (
        // <ReactHowler
        //   ref={playerRef}
        //   format={['wav']}
        //   src={`/public/all_words/${englishVersion || 'british'}/${wordToRead
        //     .toLowerCase()
        //     .replace("'", '')}.wav`}
        //   playing={true}
        //   onEnd={() => {
        //     onPlayerComplete();
        //   }}
        //   /**
        //    * play function is called on load due to some weird bug which causes ReactHowler to play the last audio file twice.
        //    * Reference: https://github.com/goldfire/howler.js/issues/899
        //    */
        //   onLoad={() => {
        //     playerRef.current?.howler.play();
        //   }}
        // />
        <WordReader separatedWord={wordToRead} onComplete={onPlayerComplete} />
      )}
    </Stack>
  );
};

export default AudioPlayer;
